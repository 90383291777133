import React from 'react'
import starIcon from '../../assets/images/star-icon.svg'
import media1 from '../../assets/images/visionar-studio/images-visionar-icon.png'
import media2 from '../../assets/images/visionar-studio/audio-file-visionar-icon.png'
import media3 from '../../assets/images/visionar-studio/video-file-visionar-icon.png'
import media4 from '../../assets/images/visionar-studio/hyperlinks-button-visionar-icon.png'

import shape from '../../assets/images/shape/shape1.svg'
import Loadable from '@loadable/component'
const OwlCarousel = Loadable(() => import('react-owl-carousel3'))

const options = {
    loop: true,
    nav: true,
    dots: false,
    autoplayHoverPause: true,
    autoplay: true,
    margin: 30,
    navText: [
        "<i class='flaticon-left-1'></i>",
        "<i class='flaticon-right-1'></i>"
    ],
    responsive: {
        0: {
            items: 1,
        },
        768: {
            items: 2,
        },
        992: {
            items: 3,
        }
    }
};

const OurFeatures = () => {
    const [display, setDisplay] = React.useState(false);

    React.useEffect(() => {
        setDisplay(true);
    }, [])

    return (
      <div className="testimonials-area pt-100">
          <div className="container">
              <div className="section-title">
                    <span className="sub-title">
                        <img src={starIcon} alt="testimonial" />
                        Features
                    </span>
                  <h2>Use Rich Media</h2>
                  <p>Link your targets with rich media formats to create the AR experience you want.</p>
              </div>

              {display ? <OwlCarousel
                className="testimonials-slides owl-carousel owl-theme"
                style={{paddingBottom: '30px'}}
                {...options}
              >
                  <div className="single-rich-media-item">
                      <div style={{justifyContent: 'center', alignItems: 'center', fontSize: '64px', color: 'white'}}>
                          {/*<img src={media1} alt="image component" />*/}
                          <i className='bx bx-image' />
                      </div>
                      <h3>Images</h3>
                      <p>Insert photos, graphics, or logos. Requirements: 8-bit or 24-bit PNG, JPG format in RGB or grayscale, CMYK is not supported.</p>
                  </div>

                  <div className="single-rich-media-item">
                      <div style={{justifyContent: 'center', alignItems: 'center', fontSize: '64px', color: 'white'}}>
                          <img src={media2} alt="image component" />
                          {/*<i className='bx bxs-volume-full' />*/}
                      </div>
                      <h3>Audio Files</h3>
                      <p>Connect image targets with impressive sound effects like voice recordings or music. Requirements: mp3 files without limitations</p>
                  </div>

                  <div className="single-rich-media-item">
                      <div style={{justifyContent: 'center', alignItems: 'center', fontSize: '64px', color: 'white'}}>
                          {/*<img src={media3} alt="image component" />*/}
                          <i className='bx bx-play-circle' />
                      </div>
                      <h3>Video Files</h3>
                      <p>Bring static pictures to live and let the customers immerse with your provided content. Requirements: min. HD ready, h.264 format</p>
                  </div>

                  <div className="single-rich-media-item">
                      <div style={{justifyContent: 'center', alignItems: 'center', fontSize: '64px', color: 'white'}}>
                          {/*<img src={media4} alt="image component" />*/}
                          <i className='bx bx-link' />
                      </div>
                      <h3>Hyperlinks & Buttons</h3>
                      <p>Improve usability for your clients by adding a link, e.g. in the form of a virtual button to your image targets. Make your content easily accessible! Requirements: none</p>
                  </div>
                  <div className="single-rich-media-item">
                      <div style={{justifyContent: 'center', alignItems: 'center', fontSize: '64px', color: 'white'}}>
                          {/*<img src={media4} alt="image component" />*/}
                          <i className='bx bx-video' />
                      </div>
                      <h3>Green Screen</h3>
                      <p>Create even more stunning experiences using latest green screen technology.</p>
                  </div>


              </OwlCarousel> : ''}

          </div>

          <div className="shape-img1">
              <img src={shape} alt="rich-media" />
          </div>
      </div>
    )
}

export default OurFeatures
