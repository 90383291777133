import React from 'react'
import {Link} from 'gatsby'
import starIcon from '../../assets/images/star-icon.svg'
import blog13 from '../../assets/images/blog/blog-img13.jpg'
import blog14 from '../../assets/images/blog/blog-img14.jpg'
import blog15 from '../../assets/images/blog/blog-img15.jpg'
import {StaticImage} from 'gatsby-plugin-image'

const OurBlog = () => {
    return (
        <section className="blog-area pt-100 pb-70 bg-fffbf5">
            <div className="container">
                <div className="section-title">
                    <span className="sub-title">
                        <img src={starIcon} alt="blog" />
                        Our Blog
                    </span>
                    <h2>Latest Valuable Insights</h2>
                    <p>Don't miss the latest news in the world of Augmented Reality.</p>
                </div>

                <div className="row">
                    <div className="col-lg-4 col-md-6">
                        <div className="single-blog-post">
                            <div className="post-image">
                                <Link to="/blog/14">
                                    <StaticImage src="../../assets/images/blog/visionar_augmented_reality_ar-trends_792-352.jpg" alt="blog" />
                                </Link>
                            </div>

                            <div className="post-content">
                                <ul className="post-meta d-flex justify-content-between align-items-center">
                                    <li>
                                        <div className="post-author d-flex align-items-center">
                                            {/* <img src={user1} className="rounded-circle" alt="blog" /> */}
                                            <span>visionar editorial office</span>
                                        </div>
                                    </li>
                                    <li>
                                        <i className='flaticon-calendar'></i> May 1, 2022
                                    </li>
                                </ul>
                                <h3>
                                    <Link to="/blog/14">
                                        Top 5 AR Trends That Will Transform How We Live in 2022
                                    </Link>
                                </h3>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                        <div className="single-blog-post">
                            <div className="post-image">
                                <Link to="/blog/13">
                                    <StaticImage src="../../assets/images/blog/visionar_augmented_reality_metaverse.png" alt="blog" />
                                </Link>
                            </div>

                            <div className="post-content">
                                <ul className="post-meta d-flex justify-content-between align-items-center">
                                    <li>
                                        <div className="post-author d-flex align-items-center">
                                            {/* <img src={user2} className="rounded-circle" alt="blog" /> */}
                                            <span>visionar editorial office</span>
                                        </div>
                                    </li>
                                    <li>
                                        <i className='flaticon-calendar'></i> Apr 8, 2022
                                    </li>
                                </ul>
                                <h3>
                                    <Link to="/blog/13">
                                        Top 5 ways in which augmented reality will be used in Metaverse
                                    </Link>
                                </h3>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                        <div className="single-blog-post">
                            <div className="post-image">
                                <Link to="/blog/12">
                                    <StaticImage src="../../assets/images/blog/blog-img15.jpg" alt="blog" />
                                </Link>
                            </div>

                            <div className="post-content">
                                <ul className="post-meta d-flex justify-content-between align-items-center">
                                    <li>
                                        <div className="post-author d-flex align-items-center">
                                            {/* <img src={user3} className="rounded-circle" alt="blog" /> */}
                                            <span>visionar editorial office</span>
                                        </div>
                                    </li>
                                    <li>
                                        <i className='flaticon-calendar'></i> Jan 29, 2020
                                    </li>
                                </ul>
                                <h3>
                                    <Link to="/blog/12">
                                    Top 5 Augmented Reality Trends in 2020
                                    </Link>
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default OurBlog
