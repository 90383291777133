import React from 'react'
import starIcon from '../../assets/images/star-icon.svg'
import service4 from '../../assets/images/services/service4.png'
import service5 from '../../assets/images/services/service5.png'
import service6 from '../../assets/images/visionar_grade_021.png'
import mobileAppDemonstration from '../../assets/videos/visionar-mobile-app-demonstration.mp4'
import studioDemonstration from '../../assets/videos/visionar-studio-loop.mp4'

const OurProducts = () => {
    return (
        <section className="overview-area ptb-100 pt-0">
            <div className="container">
                <div className="section-title">
                    <span className="sub-title">
                        <img src={starIcon} alt="products" />
                        Products</span>
                    <h2>Visionar Platform</h2>
                    <p>Creating AR has never been easier - no programming skills needed.</p>
                </div>

                {/* Left Image Style */}
                <div className="overview-box">
                    <div className="overview-image">
                        <div className="image">
                        <video style={{maxWidth: '100%'}} src={studioDemonstration} autoPlay muted loop playsInline>Your browser does not support the video tag.</video>
                        </div>
                    </div>

                    <div className="overview-content">
                        <div className="content right-content">
                            <span className="sub-title">CREATE</span>
                            <h2>Visionar Studio</h2>
                            <p>This is your creative workspace, where you link your targets with various multimedia components via drag and drop.</p>

                            <ul className="features-list">
                                <li><span><i className='bx bx-check'></i> Images & Photo Albums</span></li>
                                <li><span><i className='bx bx-check'></i> Video Files & Streaming</span></li>
                                <li><span><i className='bx bx-check'></i> Audio Files</span></li>
                                <li><span><i className='bx bx-check'></i> Links & Buttons</span></li>
                                <li><span><i className='bx bx-check'></i> Green Screen</span></li>
                                {/*<li><span><i className='bx bx-check'></i> 3D Objects</span></li>*/}
                            </ul>
                        </div>
                    </div>
                </div>
                {/* End Left Image Style */}

                {/* Right Image Style */}
                <div className="overview-box">
                    <div className="overview-content">
                        <div className="content">
                            <span className="sub-title">EXPERIENCE</span>
                            <h2>Visionar Mobile App</h2>
                            <p>The free visionar app for iOS and android presents your augmented reality content by scanning the targets, you have created in visionar studio.</p>

                            <div className="features-text">
                                <h4><i className="flaticon-tick"></i> Test In Realtime</h4>
                                <p>Upload your images, link them with multimedia content in the visionar studio and test your AR creations immediately on the visionar app.</p>
                            </div>

                            <div className="features-text">
                                <h4><i className="flaticon-tick"></i> Share Easily</h4>
                                <p>Let all others see your creation and simply share the code of your project with others. They can immediately access your experience after downloading the visionar app for free. There is no registration necessary.</p>
                            </div>
                        </div>
                    </div>

                    <div className="overview-image">
                        <div className="image">
                            <video style={{maxWidth: '100%'}} src={mobileAppDemonstration} autoPlay muted loop playsInline>Your browser does not support the video tag.</video>
                        </div>
                    </div>
                </div>
                {/* End Right Image Style */}

                {/* Left Image Style */}
                <div className="overview-box">
                    <div className="overview-image">
                        <div className="image">
                            <img src={service6} alt="features" />
                        </div>
                    </div>

                    <div className="overview-content">
                        <div className="content right-content">
                            <span className="sub-title">CUSTOMIZE</span>
                            <h2>Visionar Mobile SDK</h2>
                            <p>Visionar Mobile SDK gives you the skills to create your own mobile AR app for iOS and Android.</p>

                            <ul className="features-list">
                                <li><span><i className='bx bx-check'></i> Customized Branding</span></li>
                                <li><span><i className='bx bx-check'></i> Fast Integration</span></li>
                                <li><span><i className='bx bx-check'></i> Little Programming Skills</span></li>
                                <li><span><i className='bx bx-check'></i> Latest Technologies</span></li>
                                <li><span><i className='bx bx-check'></i> Well Documented</span></li>
                                <li><span><i className='bx bx-check'></i> Templates Available</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
                {/* End Left Image Style */}
            </div>
        </section>
    )
}

export default OurProducts
