import React from 'react'
import ReactWOW from 'react-wow'
import { Link } from 'gatsby'
import bannerImg from '../../assets/images/visionar-platform-devices_grey.png'

const Banner = () => {
  return (
    <div className="it-services-banner">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-5 col-md-12">
            <div className="main-banner-content">
              <ReactWOW delay='.1s' animation='fadeInLeft'>
                <span className="main-banner-subheading">The future of augmented reality</span>
              </ReactWOW>
              <ReactWOW delay='.1s' animation='fadeInLeft'>
                <h1 style={{marginTop: '16px', marginBottom: '30px'}}>Boost Your Business with Augmented Reality</h1>
              </ReactWOW>

              <ReactWOW delay='.1s' animation='fadeInLeft'>
                <p style={{color: '#404040', paddingRight: '4px'}}>The visionar platform provides you with a complete toolset to create your own interactive AR experiences. As easy as drag & drop. No programming skills required.</p>
              </ReactWOW>

              <ReactWOW delay='.1s' animation='fadeInRight'>
                <div className="btn-box">
                  <Link className="default-btn cta-btn" to="pricing"><i className="flaticon-right"></i> Start Your
                    14-Day FREE Trial <span></span></Link>
                  {/*<p style={{marginTop: '8px', color: '#4d88ff'}}>Watch demo video</p>*/}
                </div>
              </ReactWOW>
            </div>
          </div>

          <div className="col-lg-7 col-md-12">
            <ReactWOW delay='.1s' animation='fadeInUp'>
              <div className="main-banner-image">
                <img src={bannerImg} alt="banner" className="visionar-banner-image" />
              </div>
            </ReactWOW>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Banner