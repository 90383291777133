import React from 'react'
import starIcon from '../../assets/images/star-icon.svg'
import serviceIcon1 from '../../assets/images/benefits/1 Full Set Of Features.svg'
import serviceIcon2 from '../../assets/images/benefits/2 Easy To Use For Everyone.svg'
import serviceIcon3 from '../../assets/images/benefits/3 Simple Content Managing.svg'
import serviceIcon4 from '../../assets/images/benefits/4 Safe For GDPR.svg'
import serviceIcon5 from '../../assets/images/benefits/5 Free Support.svg'
import serviceIcon6 from '../../assets/images/benefits/6 Affordable Pricing.svg'

const OurBenefits = () => {
  return (
    <section id="benefits" className="services-area pt-100 pb-70 bg-f1f8fb">
      <div className="container">
        <div className="section-title">
                    <span className="sub-title">
                        <img src={starIcon} alt="feature" /> 
                        Benefits
                    </span>

          <h2>Why choose Visionar?</h2>
          <p>Visionar gives you everything you need to create, manage and share your own augmented reality experiences as easy as possible.</p>
        </div>

        <div className="row">
          <div className="col-lg-4 col-sm-6">
            <div className="single-services-item-box">
              <div className="icon">
                <img style={{width: '78px'}} src={serviceIcon1} alt="feature" />
              </div>
              <h3>Full Set Of Features</h3>
              {/*<p>Add rich media including images, videos, audios, links, buttons, text, GIFs, photo albums, green screen, 3D Objects & much more.</p>*/}
              <p>Add rich media including images, videos, audios, links, buttons, text, & much more is coming soon.</p>
            </div>
          </div>

          <div className="col-lg-4 col-sm-6">
            <div className="single-services-item-box">
              <div className="icon">
                <img style={{width: '78px'}} src={serviceIcon2} alt="feature" />
              </div>
              <h3>Easy To Use For Everyone</h3>
              {/*<p>No programming skills are required when using the visionar studio & mobile app. The SDK allows you an uncomplicated integration into you own mobile app.</p>*/}
              <p>No programming skills are required when using the visionar studio & mobile app.</p>
            </div>
          </div>

          <div className="col-lg-4 col-sm-6">
            <div className="single-services-item-box">
              <div className="icon">
                <img style={{width: '78px'}} src={serviceIcon3} alt="feature" />
              </div>
              <h3>Simple Content Managing</h3>
              <p>With visionar studio it is easy to manage your content in realtime - no more waiting for your app to be approved.</p>
            </div>
          </div>

          <div className="col-lg-4 col-sm-6">
            <div className="single-services-item-box">
              <div className="icon">
                <img style={{width: '78px'}} src={serviceIcon4} alt="feature" />
              </div>
              <h3>Safe For GDPR</h3>
              <p>We take care of your privacy.<br/>All data is 256-bit encrypted <br/>and saved on european <br/>servers only.</p>
            </div>
          </div>

          <div className="col-lg-4 col-sm-6">
            <div className="single-services-item-box">
              <div className="icon">
                <img style={{width: '78px'}} src={serviceIcon5} alt="feature" />
              </div>
              <h3>Free Support</h3>
              <p>If you run into problems or if you need further input the visionar team is always available and happy to assist you for free.</p>
            </div>
          </div>

          <div className="col-lg-4 col-sm-6">
            <div className="single-services-item-box">
              <div className="icon">
                <img style={{width: '78px'}} src={serviceIcon6} alt="feature" />
              </div>
              <h3>Affordable Pricing</h3>
              <p>Our flexible subscriptions are no long term contracts or commitments. Simply pay monthly and cancel if you don't need the service anymore.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default OurBenefits